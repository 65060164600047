<template>
  <div class="course-page">
    <transition name="component-fade" mode="out-in">
      <div v-if="loading" key="loader" class="f-center h-100">
        <div>
          <Loader />
        </div>
      </div>

      <div v-else key="coursePage" class="course-page__wrapper">
        <div class="course-page__course-info course-info">
          <div v-if="programId !== 'self'" class="course-info__breadcrumb container">
            <span
              v-if="programId"
              class="breadcrumb__program"
              @click="$router.push(`/programs/${programId}`)"
              >{{ programName || '...' }}</span
            >
            <span v-if="programId" class="breadcrumb__course">&nbsp;/&nbsp;&nbsp;</span>
            <span class="breadcrumb__course">{{ title || '...' }}</span>
          </div>
          <div class="course-info__bg" :style="{ 'background-image': backgroundImage }">
            <div class="course-info__bg-icon container">
              <CoverChange :meta.sync="meta" activityType="course" />
            </div>
          </div>
          <div class="course-info__wrapper container">
            <div class="course-info__subtitle">{{ $t('header.course') }}</div>
            <div class="course-info__title">
              <TextareaCustom
                :max-height="87"
                :lineHeight="29"
                :value="title"
                :placeholder="$t('placeholders.enterNameForTheCourse')"
                @blur="changeCourseName"
              />
            </div>
            <div class="course-info__description">
              <TextareaCustom
                :value="description"
                :max-height="66"
                :lineHeight="22"
                :placeholder="$t('placeholders.fillInTheCourseDescription')"
                @blur="changeCourseDescription"
              />
            </div>
            <div class="course-info__action">
              <AddButton
                class="mr-3"
                :title="$t('buttonLabels.courseContent')"
                @click="openCourseContent"
              >
                <template #suffixIcon>
                  <EditIcon class="ml-2" />
                </template>
              </AddButton>
              <AddButton
                v-if="this.programId != 'self'"
                variant="secondary"
                :title="$t('buttonLabels.statistics')"
                @click="openCourseStatistic"
              >
                <template #suffixIcon>
                  <ProgressBarIcon class="ml-2" />
                </template>
              </AddButton>
              <div class="dot-button">
                <b-dropdown
                  size="lg"
                  dropright
                  variant="link"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template #button-content>
                    <img src="@/assets/images/svg/properties-icon.svg" alt="Удалить" />
                  </template>
                  <b-dropdown-item href="#" @click.prevent="openCertificatePage">
                    {{ $t('buttonLabels.createCertificate') }}
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
            <TagsSelect
              :value="courseTags"
              :items="authorTags"
              :updateTag="updateTag"
              :isManagerRole="isManagerRole"
              @create="createTag"
              @add="addTag"
              @deleteFromCourse="deleteFromCourseTag"
              @delete="showModalRemove"
            />
            <div class="course-info__full-description">
              <EditorJsComponent :initial-content="fullDescription" @change="setFullDescription" />
            </div>
          </div>
        </div>

        <Statistic
          class="program-page__statistic"
          :class="{ 'slide-up': showStatistic }"
          :showStatistic.sync="showStatistic"
          :statisticTitle="statisticTitle"
          :statisticMeta="statisticMeta"
          :userBanList="userBanList"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import TextareaCustom from '@/components/TextAreaCustom.vue';
// eslint-disable-next-line import/no-cycle
import ActivityService from '@/services/activity.service';
import AddButton from '@/components/Buttons/AddButton.vue';
import Loader from '@/components/Loader/Loader.vue';
import Statistic from '@/components/Statistics/Statistic.vue';
import CoverChange from '@/components/CoverChange.vue';
import deviceMixin from '@/mixins/device.mixin';
import EditorJsComponent from '@/components/EditorJsComponent.vue';
import EditIcon from '@/components/Icons/EditIcon.vue';
import ProgressBarIcon from '@/components/Icons/ProgressBarIcon.vue';
import TagsSelect from '@/components/Tag/TagsSelect.vue';
import TagService from '@/services/tag.service';
import { mapGetters } from 'vuex';

export default {
  name: 'Course',
  components: {
    TagsSelect,
    EditorJsComponent,
    ProgressBarIcon,
    EditIcon,
    TextareaCustom,
    Loader,
    AddButton,
    Statistic,
    CoverChange,
  },
  mixins: [deviceMixin],
  data() {
    return {
      title: '',
      description: '',
      fullDescription: '',
      programName: '',
      programId: this.$route.params.programId,
      meta: null,
      loading: true,
      courseId: this.$route.params.courseId,
      showStatistic: false,
      statisticMeta: {
        type: 'course',
        id: this.$route.params.courseId,
        title: '',
      },
      userBanList: [],
      isManagerRole: false,

      courseTags: [],
      authorTags: [],
    };
  },
  provide() {
    return {
      openStatistic: this.openActivityStatistic,
    };
  },
  async created() {
    await ActivityService.getActivityById(this.courseId)
      .then(({ data }) => {
        this.title = data.name;
        this.description = data.description;
        this.fullDescription = JSON.parse(data?.full_description || '{}');
        this.meta = data.meta;
        this.statisticMeta.title = data.name;
        this.userBanList = data.student_ids_ban_list;
      })
      .catch(() => {});

    if (this.programId !== 'self') {
      await ActivityService.getActivityById(this.programId)
        .then(({ data }) => {
          this.programName = data.name;

          const managerBanList = data.manager_ids_ban_list;
          const managerIds = data.manager_ids;
          // eslint-disable-next-line max-len
          this.isManagerRole = managerIds.includes(this.user.id) && !managerBanList.includes(this.user.id);
        })
        .catch(console.log);
    }

    await this.loadTags();

    this.loading = false;
  },
  computed: {
    ...mapGetters({ user: 'auth/getUser' }),
    statisticTitle() {
      return this.statisticMeta.title ? this.statisticMeta.title : this.title;
    },
    backgroundImage() {
      let bg = require('@/assets/images/png/current-course-background.png');
      if (this.meta?.imageHighres) {
        bg = `https://${
          // eslint-disable-next-line no-nested-ternary
          this.$device.isDesktop
            ? this.meta.imageHighres
            : this.$device.isTablet
              ? this.meta.imageMidres
              : this.meta.imageLowres
        }`;
      }
      return `url("${bg}")`;
    },
  },
  methods: {
    changeCourseName(newName) {
      if (newName !== this.title) {
        const previousTitle = this.title;
        this.title = newName;
        ActivityService.updateActivity(this.courseId, {
          name: newName,
        }).catch((error) => {
          this.title = previousTitle;
          console.log(error);
        });
      }
    },
    changeCourseDescription(newDescription) {
      if (newDescription !== this.description) {
        const previousDescription = this.description;
        this.description = newDescription;
        ActivityService.updateActivity(this.courseId, {
          description: newDescription,
        }).catch((error) => {
          this.description = previousDescription;
          console.log(error);
        });
      }
    },
    setFullDescription(newFullDescription) {
      if (JSON.stringify(newFullDescription) !== JSON.stringify(this.fullDescription)) {
        const previousDescription = this.fullDescription;
        this.fullDescription = newFullDescription;
        ActivityService.updateActivity(this.courseId, {
          full_description: JSON.stringify(this.fullDescription),
        }).catch((error) => {
          this.fullDescription = previousDescription;
          console.log(error);
        });
      }
    },

    openCourseStatistic() {
      this.statisticMeta = {
        type: 'course',
        id: this.$route.params.courseId,
        title: this.title,
      };
      this.showStatistic = !this.showStatistic;
    },
    openCourseContent() {
      const { programId, courseId } = this.$route.params;
      this.$router.push(`/${programId}/${courseId}/content`);
    },
    openActivityStatistic(type, id, title) {
      this.statisticMeta = {
        type,
        id,
        title,
      };
      this.showStatistic = true;
    },
    openCertificatePage() {
      this.$router.push({
        name: 'CertificateConstructor',
        params: {
          programId: this.programId,
          courseId: this.courseId,
        },
      });
    },

    async loadTags() {
      const result = await TagService.getTagsByActivity(this.programId, this.courseId).catch(
        console.log,
      );
      if (result) {
        this.courseTags = result;
      }

      return TagService.getTagsByAuthor(this.programId, this.courseId)
        .then((data) => {
          if (data) {
            this.authorTags = data;
          }
        })
        .catch(console.log);
    },
    createTag(name) {
      // eslint-disable-next-line max-len
      const tagIndex = this.authorTags?.findIndex(
        (t) => t.name.toLowerCase() === name.toLowerCase(),
      );
      if (tagIndex === -1) {
        TagService.appendTag(this.programId, this.courseId, name)
          .then((tag) => {
            this.courseTags.push(tag);
            this.authorTags.push(tag);
          })
          .catch(console.log);
      } else {
        const tagCourseIndex = this.courseTags?.findIndex((t) => t.name === name);
        if (tagCourseIndex === -1) {
          this.addTag(this.authorTags[tagIndex]);
        }
      }
    },
    addTag(tag) {
      const tagIndex = this.courseTags?.findIndex((t) => t.id === tag.id);

      if (tagIndex === -1) {
        TagService.appendTag(this.programId, this.courseId, tag.name)
          .then((t) => this.courseTags.push(t))
          .catch(console.log);
      }
    },
    updateTag(t) {
      return TagService.updateTag(t).then(() => {
        const tagIndex = this.authorTags?.findIndex((tag) => tag.id === t.id);
        if (tagIndex > -1) {
          this.authorTags.splice(tagIndex, 1, t);
        }
        const tagCourseIndex = this.courseTags?.findIndex((tag) => tag.id === t.id);
        if (tagCourseIndex > -1) {
          this.courseTags.splice(tagCourseIndex, 1, t);
        }
      });
    },
    async deleteFromCourseTag(tag) {
      await TagService.removeTagFromActivity(this.programId, this.courseId, tag.id);
      this.courseTags = this.courseTags?.filter((t) => t.id !== tag.id);
    },
    deleteTag(tag) {
      TagService.deleteTag(tag.id).then(() => {
        const tagIndex = this.authorTags?.findIndex((t) => t.id === tag.id);
        if (tagIndex > -1) {
          this.authorTags.splice(tagIndex, 1);
        }
      });
    },
    showModalRemove(tag) {
      this.$bvModal
        .msgBoxConfirm(this.$t('supportText.thisActionCannotBeUndoneTag'), {
          size: 'sm',
          okVariant: 'secondary',
          cancelVariant: 'primary',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'lms-modal__footer border-top-0',
          centered: true,
          bodyClass: 'lms-modal__body',
          okTitle: this.$t('buttonLabels.delete'),
          cancelTitle: this.$t('buttonLabels.cancel'),
        })
        .then((isConfirmed) => {
          if (isConfirmed) {
            this.deleteTag(tag);
          }
        })
        .catch(console.log);
    },
  },
  watch: {
    showStatistic: {
      handler(bool) {
        const showStatistic = () => {
          // eslint-disable-next-line no-unused-expressions
          const mainLayout = document.getElementById('mainLayout');
          mainLayout.scrollTop = 0;
          document.getElementById('mainLayout').classList.add('scrollHidden');
        };
        // eslint-disable-next-line no-unused-expressions
        bool
          ? setTimeout(showStatistic, 600)
          : document.getElementById('mainLayout').classList.remove('scrollHidden');
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.content__list.container {
  ::v-deep .material-tile {
    margin-bottom: 1rem;
  }
}

.course-page__dropdown ::v-deep .btn:focus {
  box-shadow: none;
}

.course-page__dropdown ::v-deep .dropdown-item:hover {
  background: #273344;
  color: #f2f2f2;
}
</style>
