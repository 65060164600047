<template>
  <div class="tags-select" v-click-outside="setInactive">
    <div class="tags-select__control" v-show="!active">
      <TagBadge
        v-for="selected in value"
        :key="selected.name"
        :title="selected.name"
        :update="updateTag"
        :tag="selected"
        @clickSuffix="deleteFromCourse(selected)"
        :isEditable="editable"
      >
        <template #suffixIcon>
          <BlackSmallCrossIcon />
        </template>
      </TagBadge>

      <TagBadge
        v-if="editable"
        class="cursor-pointer"
        variant="secondary"
        :title="$t('buttonLabels.addTag')"
        @clickPrefix="setActive"
        @click="setActive"
      >
        <template #prefixIcon>
          <SmallOrangePlusIcon />
        </template>
      </TagBadge>
    </div>

    <div class="tags-select" v-show="active">
      <div class="tags-select__input-container active">
        <TagBadge
          v-for="selected in value"
          :key="selected.name"
          :title="selected.name"
          :tag="selected"
          :update="updateTag"
          :isEditable="editable"
          @clickSuffix="deleteFromCourse(selected)"
        >
          <template #suffixIcon>
            <BlackSmallCrossIcon />
          </template>
        </TagBadge>

        <b-form-input
          ref="tagsSelectInput"
          class="tags-select__input"
          v-model="text"
          size="sm"
          autocomplete="no"
          @keyup.enter="createTag"
          @keydown.delete="clearOrDeleteLastTag"
        />
      </div>

      <b-collapse visible class="tags-select__list scroll" tag="ul" ref="tagsSelectItemsList">
        <li v-for="item in filteredTags" :key="`tag-${item.id}`" @click="addTag(item)">
          <div>{{ item.name }}</div>
          <TrashIcon v-if="!isManagerRole" @click="$emit('delete', item)" />
        </li>

        <li @click="createTag" v-if="!hasExactTagName && text.length > 0" key="new-tag">
          <span>
            {{ $t('buttonLabels.create') }}
            <TagBadge class="ml-2" :title="text" />
          </span>
        </li>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import TagBadge from '@/components/Tag/TagBadge.vue';
import BlackSmallCrossIcon from '@/components/Icons/BlackSmallCrossIcon.vue';
import SmallOrangePlusIcon from '@/components/Icons/SmallOrangePlusIcon.vue';
import TrashIcon from '@/components/Icons/TrashIcon.vue';
import { mapActions } from 'vuex';

export default {
  components: {
    TrashIcon,
    TagBadge,
    BlackSmallCrossIcon,
    SmallOrangePlusIcon,
  },
  props: {
    value: {
      type: Array,
      required: false,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    updateTag: {
      type: Function,
      default: () => {},
    },
    editable: {
      type: Boolean,
      default: true,
    },
    isManagerRole: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    text: '',
    active: false,
  }),
  computed: {
    filteredTags() {
      const lowerCasedText = this.text.toLowerCase();

      return this.items.filter(
        // eslint-disable-next-line max-len
        (item) => item.name.toLowerCase().includes(lowerCasedText) && !this.isTagAlreadySelected(item.id),
      );
    },
    hasExactTagName() {
      if (!this.items.length) {
        return false;
      }
      const exactTag = this.items.filter((item) => item.name === this.text);

      return !!exactTag.length;
    },
  },
  methods: {
    ...mapActions({ setToaster: 'toaster/setToaster' }),
    isTagAlreadySelected(id) {
      return Boolean(this.value.find((v) => v.id === id));
    },
    addTag(tag) {
      this.$emit('add', tag);

      this.setFocusOnInput();
    },
    createTag() {
      if (this.text.length > 64) {
        return this.setToaster({
          type: 'toast-warning',
          toast: {
            title: this.$t('errorMessages.tagCreate'),
            body: this.$tc('errorMessages.createTagOverLimit', this.text.length),
          },
        });
      }
      this.$emit('create', this.text);

      this.setFocusOnInput();
    },
    deleteFromCourse(tag) {
      this.$emit('deleteFromCourse', tag);
    },
    clearOrDeleteLastTag() {
      if (!this.text.length && this.value.length) {
        const lastTag = this.value[this.value.length - 1];

        this.deleteFromCourse(lastTag);
      }
    },
    setActive() {
      this.active = true;

      this.setFocusOnInput();
    },
    setInactive() {
      this.active = false;
    },
    setFocusOnInput() {
      // Clear out the text input and make focus on it again
      this.text = '';
      // In case to be absolutely sure that focus would work
      // wait for text input to be rendered and visible in the DOM
      this.$nextTick(() => {
        this.$refs.tagsSelectInput.focus();
      });
    },
    handleUpdate(tag, name) {
      this.$emit('updateTag', { ...tag, name });
    },
  },
  mounted() {
    // This is used to tell ClickOutside directive to ignore in the list of
    // elements that can be clicked without running a callback
    this.ignoredElements = this.$refs.tagsSelectItemsList.$el;
  },
};
</script>
