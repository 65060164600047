<template>
  <!-- eslint-disable max-len -->
  <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.0625 2.375C13.0625 2.06006 13.1876 1.75801 13.4103 1.53531C13.633 1.31261 13.9351 1.1875 14.25 1.1875H16.625C16.9399 1.1875 17.242 1.31261 17.4647 1.53531C17.6874 1.75801 17.8125 2.06006 17.8125 2.375V16.625H18.4062C18.5637 16.625 18.7147 16.6876 18.8261 16.7989C18.9374 16.9103 19 17.0613 19 17.2188C19 17.3762 18.9374 17.5272 18.8261 17.6386C18.7147 17.7499 18.5637 17.8125 18.4062 17.8125H0.59375C0.436278 17.8125 0.285255 17.7499 0.173905 17.6386C0.0625556 17.5272 0 17.3762 0 17.2188C0 17.0613 0.0625556 16.9103 0.173905 16.7989C0.285255 16.6876 0.436278 16.625 0.59375 16.625H1.1875V13.0625C1.1875 12.7476 1.31261 12.4455 1.53531 12.2228C1.75801 12.0001 2.06006 11.875 2.375 11.875H4.75C5.06494 11.875 5.36699 12.0001 5.58969 12.2228C5.81239 12.4455 5.9375 12.7476 5.9375 13.0625V16.625H7.125V8.3125C7.125 7.99756 7.25011 7.69551 7.47281 7.47281C7.69551 7.25011 7.99756 7.125 8.3125 7.125H10.6875C11.0024 7.125 11.3045 7.25011 11.5272 7.47281C11.7499 7.69551 11.875 7.99756 11.875 8.3125V16.625H13.0625V2.375Z"
      fill="#FAA433"
    />
  </svg>
</template>

<script>
export default {
  name: 'ProgressBarIcon',
};
</script>
